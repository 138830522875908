/*

 Basic styles for FoundationPress demo site

*/


// Reset margin and padding on figure element
figure {
	margin: 0;
	padding: 0;
}

// Add some basic styling for figcaption element
figcaption {
	margin-bottom: rem-calc(20);
	margin-top: rem-calc(10);
	color: #6f6f6f;
}

// Add the alignment classes used by the WordPress media library

.alignleft {
	float: left;
}

.alignright {
	float: right;
}

.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

blockquote.alignleft,
img.alignleft {
	margin: rem-calc(7) rem-calc(24) rem-calc(7) 0;
}

.wp-caption.alignleft {
	margin: rem-calc(7) rem-calc(14) rem-calc(7) 0;
}

blockquote.alignright,
img.alignright {
	margin: rem-calc(7) 0 rem-calc(7) rem-calc(24);
}


// Add some whitespace between top bar and content
.top-bar-container, .tab-bar {
	// margin-bottom: rem-calc(32);
}

// Fix the issue where the WP adminbar overlaps the mobile menu
#wpadminbar {
	position: fixed !important;
}
.top-bar-container {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	height: rem-calc(90);
	background: $black;
	overflow: visible;
	transform: translate3d(0,0,0);
	transition: transform .2s ease-in-out;
	z-index: 9999;

	.top-bar {
		height: rem-calc(90);
		background: $black;
		overflow: visible;
		transition: max-height .2s ease-in-out;

		.name {
			position: absolute;
			transform: translate3d(0,0,0);
			transition: transform .2s ease-in-out;
			z-index: 99;

			img {
				width: 100px;
				margin: 10px 0 10px 50px;
				max-width: none;
			}
		}
	}

	&.small {
		transform: translate3d(0, -40px, 0);

		.top-bar-section ul.right {
			margin-top: 50px;

			li {
				background-color: black;

				a:not(.button) {
					color: white;
				}

				.dropdown {
					opacity: 1;
				}

				&:not(.has-form).has-dropdown li a:not(.button) {
					color: white;

					&:hover {
						color: darken(white, 5);
					}
				}
			}
		}
		.name img {
			width: 55px;
			margin: 5px 0 5px 50px;
          	transform: translate3d(0, 45px, 0);
		}
	}
}

.top-bar-section ul.right {
	margin-top: 28px;

	li:not(.has-form) {
		border-right: none;

		&.active {

			a:not(.button) {
				background: $black;
				color: $yellow;
				text-shadow: 0 0.5px 0px #000;
				border-top: 2px solid $yellow;
			}
		}

		&.has-dropdown {
			a {
				&:after {
					display: none;
				}
			}

			.dropdown {
				left: 0;
				right: auto;
			}

			li {
				a:not(.button) {
					font-size: rem-calc(15);
					padding: .3rem .8rem;
					color: rgba( 255, 255, 255, .7);
					background-color: #000;
					border-top: 1px solid #333;

					&:hover {
						background-color: #000;
						color: rgba( 255, 255, 255, 1);
					}
				}

				&:nth-of-type(3) {
					a:not(.button) {
						border-top: none;
					}
				}
			}
		}

		a:not(.button) {
			color: fff;
			font-weight: 400;
			font-size: rem-calc(15);
			background: $black;
			border-top: 2px solid transparent;
			padding: 0 .9rem;
			transition: 0.2s all ease-in;
			line-height: 2;

			&:hover {
				background: $black;
				color: $yellow20;
			}
		}
	}
}

.top-bar-section .dropdown li:not(.has-form):not(.active)>a:not(.button) {
	background-color: $black;
		&:hover {
			background-color: $ghost;
			color: $tuatara;
		}
}
.top-bar-section .dropdown ul li:not(.has-form).active>a:not(.button) {
	background-color: $ghost;
	color: $tuatara;
}

// Add some whitespace above the footer for medium-up screens
@media #{$medium-up} {
	footer.row {
		margin-top: rem-calc(32);
	}
}

body {
	background-color: $light-black;
}

.drops {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 200%;
	background: url(../img/drops.png) 0 0 repeat-y;
	z-index: 2;
}

// container

.container {
	// @include background(linear-gradient($light-black, $black) left);
	background-color: $black;
	padding-bottom: 75px;
}

.row {
	// background: $oil;
}

.main {
	padding-top: rem-calc(30);
}

.parallax {
	position: relative;
	display: block;
	width: 100%;
	height: 400px;
	background: url(../img/carp2.jpg) no-repeat 0 0;
	background-size: 100%;
	background-attachment: fixed;
	box-shadow: 0px 0px 10px 5px rgb(0, 0, 0) inset;
	overflow: hidden;

	&.down {
		margin-top: 100px;
	}
}

// typography
.entry-title {
	text-align: center;
	border-bottom: 1px solid $yellow20;
	border-top: 1px solid $yellow20;
	@include background(linear-gradient($black, $oil, $black) left);
	font-size: 40px;
	text-transform: uppercase;
	color: $ghost;
	font-weight: 900;
	text-shadow: 2px 2px 5px $jet;
	margin-bottom: 15px;
}

// helpers
.hide {
	display: none;
}
blockquote p {
	color: $yellow20;
}

#sidebar {
	margin-top: 35px;
}
// posts list style
.attachment-post-thumbnail {
	margin: 0 auto;
	padding: 1px;
	// border: 1px solid $gold;
	background: $aluminum;
	// background: $gold;
	// max-width: 95%;
}
.grid-el {
	border-bottom: 1px solid $jet;
	border-left: 1px solid $jet;
	transition: 0.2s all ease-in;
	box-shadow: 0px 0px 5px 0px $tuatara;
	cursor: pointer;

	&:nth-of-type(3) {
		border-right: none;
		border-left: none;
	}

	.title-wrapper {
		min-height: 75px;
	}

	&:hover {
		box-shadow: 0px 0px 5px 0px $yellow;
		a {
			color: $oil;
		}
	}

	a {
		color: $iron;
	}

	h4 {
		text-align: center;
		color: $yellow20;
		margin-top: 10px;
		text-shadow: 0 2px 0 $black;
		display: block; /* Fallback for non-webkit */
	  display: -webkit-box;
	  max-width: 264px;
	  height: ($font-size*$line-height*$lines-to-show); /* Fallback for non-webkit */
	  margin: 0 auto;
	  font-size: $font-size;
	  line-height: $line-height;
	  -webkit-line-clamp: $lines-to-show;
	  -webkit-box-orient: vertical;
	  overflow: hidden;
	  text-overflow: ellipsis;
	}
	p {
		font-size: rem-calc(12);
		padding: 10px;
	}
	.review {
		text-align: center;
	}
	.review-total-only {
		text-align: center;
		margin-bottom: 10px;
		background-color: transparent;
		color: $yellow;
	}

	figure {
		text-align: center;
		margin-bottom: 10px;
	}
}

.long-el {
	position: relative;
	margin-bottom: 30px;
	transition: 0.2s all ease;
	border-top: 1px solid $jet;
	border-bottom: 1px solid $jet;
	box-shadow: 0px 0px 25px 0px $jet;
	border-radius: 10px;

	.ico-fish {
		@include position(absolute, 5px 5px none none);
		width: 50px;
		height: 50px;
		background: url(../img/ico-yellow.png) 0 0 no-repeat;
	}

	&:hover {
		// background-color: $jet;
		box-shadow: 0px 0px 5px 0px $yellow;
		& > img {
			// background-color: $yellow;
		}
	}

	.desc {
		// min-height: 280px;
	}

	a {
		color: $smoke;
	}

	h4 {
		text-align: center;
		color: $yellow;
		margin-top: 15px;
		margin-bottom: 5px;
	}
	.thumb-wrapper {
		text-align: center;
		img {
			margin: 15px 0;
			padding: 2px;
			background-color: $ghost;
			border: 1px solid $iron;
			border-radius: 3px;
		}
	}

}

.site-footer {
	background-color: $black;
	border-top: 1px solid $tuatara;

	h6 {
		font-weight: bold;
		text-shadow: 0 1px 0 $tuatara;
	}

	ul {
		list-style: none;

		li {

			a {
				padding-left: 10px;
				transition: 0.2s padding ease-in;
			}


			&:hover {
				&:before {
					margin-left: -2px;
				}
				a {
					padding-left: 2px;
				}
			}

			&:before {
				content: "\f061";
				margin-left: -15px;
				font: normal normal normal 14px/1 FontAwesome;
 				font-size: inherit;
   			text-rendering: auto;
   			transition: 0.2s margin ease-in;
   			-webkit-font-smoothing: antialiased;
   			-moz-osx-font-smoothing: grayscale;
			}
		}
	}
}

// footer
.main-footer {
	height: rem-calc(75);
	border-top: 1px solid $tuatara;
	text-align: center;
	padding-top: 20px;
	color:	$smooth-gold;
	text-shadow: 0 0.5px 0 $black;
	font-size: rem-calc(24);
	font-weight: 100;
}
.row.widget {
	padding-bottom: 5px;
	padding-top: 5px;
	margin-bottom: 5px;
	border-bottom: 1px solid $tuatara;

	h6 {
		font-weight: bold;
		color: $yellow;
		text-shadow: 0 0.5px 0 $tuatara;
	}

	ul {
		list-style: none;

		li {
			a {
				color: lighten($yellow, 30);
				transition: 0.2s color ease-in;
				padding-left: 10px;
				transition: 0.2s padding ease-in;

				&:hover {
					color: $yellow;
				}
			}

			&:hover {
				&:before {
					margin-left: -2px;
				}
				a {
					padding-left: 2px;
				}
			}

			&:before {
				content: "\f061";
				margin-left: -15px;
				font: normal normal normal 14px/1 FontAwesome;
 				font-size: inherit;
   			text-rendering: auto;
   			transition: 0.2s margin ease-in;
   			-webkit-font-smoothing: antialiased;
   			-moz-osx-font-smoothing: grayscale;
			}
		}
		&.instagram-pics li {
			&:before {
				content: none;
				margin-left: none;
			}
		}
	}
}

.small-block-grid-3 li {
	text-align: center;
}

.head-img {
	text-align: center;
	border-bottom: 1px solid $steel;
	margin-bottom: 15px;

	.review-image {
		margin: 10px auto 20px;
		max-height: 300px;
		width: auto;
	}
}

#sidebar {
	article {
		&:last-of-type {
			border-bottom: none;
		}
	}

	.widget_sp_image {
		img {

		}
	}
}


.acf-map {
	width: 100%;
	height: 400px;
	border: #ccc solid 1px;
	margin: 20px 0;
}

.slideshowlink {
	display: none;
}

