// colors
$gold: #DAA520;
$light-gold: #e6c183;
$dark-gold: #B8860B;
$golden-gold: #FFD700;
$smooth-gold: #EEE8AA;
$light-black: #111111;

// yellow
$yello: #FFFF00;
$light-yellow: #FFFFE0;
$yellow: lighten($yello, 10);
$yellow20: lighten($yello, 20);
$yellow30: lighten($yello, 30);

$font-size: rem-calc(22);
$line-height: 1.2;
$lines-to-show: 2;

// $white       : #FFFFFF;
// $ghost       : #FAFAFA;
// $snow        : #F9F9F9;
// $vapor       : #F6F6F6;
// $white-smoke : #F5F5F5;
// $silver      : #EFEFEF;
// $smoke       : #EEEEEE;
// $gainsboro   : #DDDDDD;
// $iron        : #CCCCCC;
// $base        : #AAAAAA;
// $aluminum    : #999999;
// $jumbo       : #888888;
// $monsoon     : #777777;
// $steel       : #666666;
// $charcoal    : #555555;
// $tuatara     : #444444;
// $oil         : #333333;
// $jet         : #222222;
// $black       : #000000;